export type AllowedLanguages = "en" | "de";

export const langs = {
  allowed: ["en", "de"],
  defaultLanguage: "en",
  en: {
    production: ["recipes.seonnyn.com", "recipes.seonnyn.de"],
    development: ["recipes.seonnyn.com.local", "recipes.seonnyn.de.local"],
    test: ["recipes.seonnyn.com.test", "recipes.seonnyn.de.test"],
  },
  de: {
    production: ["rezepte.seonnyn.de", "rezepte.seonnyn.com"],
    development: ["rezepte.seonnyn.de.local", "rezepte.seonnyn.com.local"],
    test: ["rezepte.seonnyn.de.test", "rezepte.seonnyn.com.test"],
  },
};

export default langs;
