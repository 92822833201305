import React, { FC, useEffect, useState } from "react";
import { AllowedLanguages } from "../config/languages";
import { AllowedEnvironments } from "../config/general";
import { useParams } from "react-router-dom";
import MainLayout from "../layouts/Main";
import { Box, Divider, Skeleton, Typography } from "@mui/material";

interface RecipeProps {
  lang: AllowedLanguages;
  env: AllowedEnvironments;
}

type RecipeParams = {
  slug: string;
};

export const Recipe: FC<RecipeProps> = ({ lang, env }: RecipeProps) => {
  const { slug } = useParams<RecipeParams>();
  const [recipeLoaded, setRecipeLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRecipeLoaded(true);
    }, 10000);
  }, []);

  if (!recipeLoaded) {
    return (
      <MainLayout containerSize={"lg"}>
        <Typography variant={"h1"}>
          <Skeleton />
        </Typography>
        <Skeleton variant="rectangular" width={"100%"} height={450} />
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 10 }}>
          <Skeleton variant={"text"} width={"20%"} />
          <Skeleton variant={"text"} width={"15%"} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 10 }}>
          <Skeleton variant={"text"} width={"20%"} />
          <Skeleton variant={"text"} width={"15%"} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 10 }}>
          <Skeleton variant={"text"} width={"20%"} />
          <Skeleton variant={"text"} width={"20%"} />
          <Skeleton variant={"text"} width={"15%"} />
        </Box>
        <Divider />
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: 10 }}>
          <Skeleton variant={"text"} width={"15%"} />
          <Skeleton variant={"text"} width={"15%"} />
          <Skeleton variant={"text"} width={"15%"} />
        </Box>
        <Divider />
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "5%" }}>
          <Box sx={{ width: "25%" }}>
            <Typography variant={"h3"}>
              <Skeleton />
            </Typography>
            <Skeleton variant={"text"} />
            <Skeleton variant={"text"} width={"90%"} />
            <Skeleton variant={"text"} width={"80%"} />
            <Skeleton variant={"text"} />
            <Skeleton variant={"text"} width={"80%"} />
            <Skeleton variant={"text"} width={"50%"} />
            <Skeleton variant={"text"} width={"90%"} />
          </Box>
          <Box sx={{ width: "70%" }}>
            <Typography variant={"h3"}>
              <Skeleton />
            </Typography>
            {["100%", "90%", "80%", "70%", "80%", "70%", "60%", "40%", "50%", "30%"].map((i) => (
              <Box sx={{ display: "flex", justifyContent: "flex-start" }} key={`skel-box-${i}`}>
                <Skeleton variant={"text"} width={i} />
              </Box>
            ))}
          </Box>
        </Box>
      </MainLayout>
    );
  } else {
    return (
      <MainLayout containerSize={"lg"}>
        <h1>
          Recipe Page {lang} - {env} - {slug}
        </h1>
      </MainLayout>
    );
  }
};

export default Recipe;
