import React, { FC, useState } from "react";
import { Container, CssBaseline, PaletteMode, ThemeProvider } from "@mui/material";
import LocalStorage from "../helpers/LocalStorage";
import { createTheme } from "@mui/material/styles";
import { MyAppBar } from "../components/MyAppBar";

interface MainLayoutProps {
  children: React.ReactNode | React.ReactNodeArray;
  containerSize: "sm" | "md" | "lg";
}

export const MainLayout: FC<MainLayoutProps> = ({ children, containerSize }: MainLayoutProps) => {
  const [currentTheme, setTheme] = useState<PaletteMode>(LocalStorage.get("userTheme", "light") as PaletteMode);
  const [drawerState, setDrawerState] = useState<boolean>(false);

  const theme = createTheme({
    palette: {
      mode: currentTheme,
    },
  });

  const toggleDrawer = (newDrawerState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerState(newDrawerState);
  };

  const changeTheme = () => {
    const newTheme = currentTheme === "light" ? "dark" : "light";

    setTheme(newTheme);
    LocalStorage.set("userTheme", newTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <MyAppBar
          currentTheme={currentTheme}
          changeTheme={changeTheme}
          drawerState={drawerState}
          toggleDrawer={toggleDrawer}
        />
        <Container maxWidth={containerSize}>{children}</Container>
      </div>
    </ThemeProvider>
  );
};

export default MainLayout;
