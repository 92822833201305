import React, { FC } from "react";
import { AllowedEnvironments } from "../config/general";
import { AllowedLanguages } from "../config/languages";

interface AccessForbiddenProps {
  lang: AllowedLanguages;
  env: AllowedEnvironments;
}

export const AccessForbidden: FC<AccessForbiddenProps> = ({ lang, env }: AccessForbiddenProps) => (
  <>
    <h1>{lang === "en" ? "Access Forbidden - 403!" : "Zugang verboten - 403!"}</h1>
    <h2>
      Debug: <code>Environment: {env}</code>
    </h2>
  </>
);

export default AccessForbidden;
