export class LocalStorage {
  static get(identifier: string, def = ""): string {
    return localStorage.getItem(identifier) || def;
  }

  static set(identifier: string, value: string): void {
    localStorage.setItem(identifier, value);
  }

  static consume(identifier: string): string | boolean {
    const value = localStorage.getItem(identifier) || null;

    if (value === null) {
      return false;
    }

    localStorage.removeItem(identifier);
    return value;
  }
}

export default LocalStorage;
