import React, { FC } from "react";
import { AllowedLanguages } from "./config/languages";
import { AllowedEnvironments } from "./config/general";
import { MainLayout } from "./layouts/Main";

interface AppProps {
  lang: AllowedLanguages;
  env: AllowedEnvironments;
}

export const App: FC<AppProps> = ({ lang, env }: AppProps) => {
  return (
    <MainLayout containerSize={"sm"}>
      <header className="App-header">
        <h1>Language: {lang}</h1>
        <h2>Environment: {env}</h2>
      </header>
    </MainLayout>
  );
};

export default App;
