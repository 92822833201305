import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import config from "./config";
import { AllowedLanguages } from "./config/languages";
import { AllowedEnvironments } from "./config/general";
import AccessForbidden from "./pages/AccessForbidden";
import Recipe from "./pages/Recipe";

const environment: AllowedEnvironments = process.env.NODE_ENV || "development";

// Depending on the domain add the language
let lang: AllowedLanguages;
let allowedHost: boolean;

if (config.languages.de[environment].includes(window.location.hostname)) {
  lang = "de";
  allowedHost = true;
} else if (config.languages.en[environment].includes(window.location.hostname)) {
  lang = "en";
  allowedHost = true;
} else {
  lang = config.languages.defaultLanguage as AllowedLanguages;
  allowedHost = false;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App lang={lang} env={environment} />,
  },
  {
    path: "/r/:slug",
    element: <Recipe lang={lang} env={environment} />,
  },
]);

if (!allowedHost) {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <AccessForbidden lang={lang} env={environment} />
    </React.StrictMode>,
  );
} else {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
