import React, { FC } from "react";
import {
  styled,
  alpha,
  InputBase,
  Box,
  IconButton,
  Toolbar,
  AppBar,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  SwipeableDrawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import InboxIcon from "@mui/icons-material/Inbox";
import MailIcon from "@mui/icons-material/Mail";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "32ch",
      },
    },
  },
}));

interface MyAppBarProps {
  currentTheme: "dark" | "light";
  changeTheme: () => void;
  drawerState: boolean;
  toggleDrawer: (arg1: boolean) => (ev: React.KeyboardEvent | React.MouseEvent) => void;
}

export const MyAppBar: FC<MyAppBarProps> = ({
  currentTheme,
  changeTheme,
  drawerState,
  toggleDrawer,
}: MyAppBarProps) => {
  const navigationList = (
    <Box sx={{ width: "auto" }} role={"presentation"} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {["Home", "Categories", "Contact", "About Us"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position={"static"}>
        <Toolbar>
          <React.Fragment key={"main-menu-button"}>
            <IconButton
              size={"large"}
              edge={"start"}
              color={"inherit"}
              aria-label={"open drawer"}
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor={"top"}
              open={drawerState}
              onOpen={toggleDrawer(true)}
              onClose={toggleDrawer(false)}
            >
              {navigationList}
            </SwipeableDrawer>
          </React.Fragment>
          {currentTheme === "light" ? (
            <IconButton size={"large"} edge={"start"} color={"inherit"} sx={{ mr: 2 }} onClick={changeTheme}>
              <Brightness3Icon />
            </IconButton>
          ) : (
            <IconButton size={"large"} edge={"start"} color={"inherit"} sx={{ mr: 2 }} onClick={changeTheme}>
              <WbSunnyIcon />
            </IconButton>
          )}
          <Typography
            variant={"h6"}
            noWrap
            component={"div"}
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Seo-Recipes
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder={"Search..."} inputProps={{ "aria-label": "search" }} />
          </Search>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
